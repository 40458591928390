import Api from "./Api";

const register = async (data) => {
  return Api.postRequest({
    endpoint: "/register",
    data: data,
  });
};

const login = async (data) => {
  return Api.postRequest({
    endpoint: "/login",
    data: data,
  });
};

const logout = async () => {
  return Api.postRequest({
    endpoint: "/logout",
  });
};

const auth = async () => {
  return Api.getRequest({ endpoint: "/me" });
};

const csrf = async () => {
  return Api.getRequestWithoutBaseApi({ endpoint: "/sanctum/csrf-cookie" });
};

const verify = async (payload, id) => {
  const path = `/email/verify/${id}`;
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({ endpoint });
};

const resend = async () => {
  return Api.getRequest({ endpoint: "/email/resend" });
};

const forgot = async (data) => {
  return Api.postRequest({
    endpoint: "/password/email",
    data: data,
  });
};

const reset = async (data) => {
  return Api.postRequest({
    endpoint: "/password/reset",
    data: data,
  });
};

const verified = async () => {
  return Api.getRequest({ endpoint: "/email/verified" });
};

const checkOldPass = async (data) => {
  return Api.postRequest({
    endpoint: "/password/check-old-pass",
    data: data,
  });
};

const updatePassword = async (data) => {
  return Api.postRequest({
    endpoint: "/password/update-password",
    data: data,
  });
};

export default {
  login,
  logout,
  register,
  auth,
  csrf,
  verify,
  resend,
  forgot,
  reset,
  verified,
  checkOldPass,
  updatePassword,
};
