import User from "@/services/User";
export default {
  namespaced: true,
  state: {
    users: [],
  },
  getters: {},
  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const {
        data: { data },
      } = await User.all(payload);

      commit("SET_USERS", data);
    },

    // eslint-disable-next-line no-empty-pattern
    async update({}, payload) {
      return User.update(payload);
    },
  },
};
