<template>
  <v-app>
    <snackbar-component />
    <GOverlay />
    <v-container>
      <header-component />
    </v-container>
    <v-container>
      <v-main>
        <verification-component />
        <router-view />
      </v-main>
    </v-container>
    <v-footer dark padless class="indigo lighten-1 white--text text-center">
      <v-container>
        <v-card flat tile class="indigo lighten-1 white--text text-center">
          <v-card-text>
            <!--            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white&#45;&#45;text"
              icon
            >
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>-->
          </v-card-text>

          <v-card-text class="white--text pt-0">
            At PrismTax, we are people-driven. We go beyond a typical accounting
            firm that just prepares your taxes: our primary goal is to empower
            our clients to accomplish their financial goals by employing
            proactive strategies to minimize taxes and maximize wealth for your
            family. family. Here, we understand that every business comes with
            its own unique set of challenges and stories. Prism Tax doesn’t
            believe in a one-size-fits-all-approach. Our team of experts will
            work side-by-side with you to develop a holistic picture of your
            business’ current financial status; then, we’ll build a personalized
            action plan to help you build your best financial future.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} —
            <a
              href="https://www.prismtaxstrategies.com/"
              class="links"
              target="_blank"
            >
              <strong>Prism Tax Strategies Inc</strong>
            </a>
          </v-card-text>
        </v-card>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import SnackbarComponent from "@/components/Snackbar";
import HeaderComponent from "@/components/menu/Header";
import GOverlay from "@/components/GOverlay";
import VerificationComponent from "@/components/VerificationComponent";
export default {
  name: "App",
  metaInfo: {
    title: "Prism Admin",
    titleTemplate: "Home | %s",
    meta: [
      { name: "robots", content: "noindex" },
      { name: "googlebot", content: "noindex" },
    ],
  },
  components: {
    VerificationComponent,
    GOverlay,
    HeaderComponent,
    SnackbarComponent,
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #fff !important;
}
.v-main {
  padding: 32px 0 96px 0 !important;
}

.text-transform-capitalize {
  text-transform: capitalize !important;
  font-size: 18px !important;
}

.links {
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer !important;
}
</style>
