import Support from "@/services/Support";
export default {
  namespaced: true,
  state: {
    supports: [],
  },
  getters: {},
  mutations: {
    SET_SUPPORTS(state, payload) {
      state.supports = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const {
        data: { data },
      } = await Support.all(payload);

      commit("SET_SUPPORTS", data);
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return Support.get(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async store({}, payload) {
      return Support.store(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async update({}, payload) {
      return Support.update(payload);
    },
  },
};
