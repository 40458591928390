import Upload from "@/services/Upload";
export default {
  namespaced: true,
  state: {
    files: [],
  },
  getters: {},
  mutations: {
    SET_FILES(state, payload) {
      state.files = payload;
    },
  },
  actions: {
    async all({ commit }, payload) {
      const {
        data: { data },
      } = await Upload.all(payload);

      commit("SET_FILES", data);
    },
    // eslint-disable-next-line no-unused-vars,no-empty-pattern
    async get({}, payload) {
      return Upload.get(payload);
    },
    // eslint-disable-next-line no-empty-pattern
    async store({}, payload) {
      let { remarks, files } = payload;
      let data = new FormData();
      data.append("remarks", remarks);
      files.forEach((item) => {
        data.append("files[]", item);
      });

      return Upload.store(data);
    },
  },
};
