import Api from "@/services/Api";

const all = (payload) => {
  const path = "/user/files";
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const get = (payload) => {
  const endpoint = `/user/file/${payload.id}`;
  return Api.getRequest({
    endpoint,
  });
};

const store = async (data) => {
  return Api.postRequestWithForm({
    endpoint: "/user/file/store",
    data: data,
  });
};

const update = async (data) => {
  return Api.postRequestWithForm({
    endpoint: "/user/file/update",
    data: data,
  });
};

export default {
  all,
  get,
  store,
  update,
};
