import Api from "@/services/Api";

const all = (payload) => {
  const path = "/users";
  const endpoint = payload
    ? `${
        path +
        "?" +
        Object.keys(payload)
          .map((key) => key + "=" + payload[key])
          .join("&")
      }`
    : path;

  return Api.getRequest({
    endpoint,
  });
};

const update = async (data) => {
  return Api.postRequest({
    endpoint: "/user/update",
    data: data,
  });
};

export default {
  all,
  update,
};
